export const adminRouteAlias = {
    cars: {
        location: "cars",
    },
    parts: {
        location: "parts",
    },
    soldParts: {
        location: "parts/sold",
    },
    archivedParts: {
        location: "parts/archived",
    },
    sales: {
        location: "sales",
    },
    warehouses: {
        location: "warehouses",
    },
    marketplaces: {
        location: "sales/marketplaces",
    },
    shop: {
        location: "shop",
    },
    newCar: {
        location: "newCar",
    },
    newPart: {
        location: "newPart",
    },
    payWall: {
        location: "payWall",
    },
    documents: {
        location: "documents",
    },
    draftsOfParts: {
        location: "parts/drafts",
    },
    onboarding: {
        location: "onboarding",
    },
    onboardingCarDisassembly: {
        location: "onboarding/carDisassembly",
    },
    onboardingDownloadFromFile: {
        location: "onboarding/downloadFromFile",
    },
}

export const getAdminNavigationPath = (location: string) => `/admin/${location}`
