import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"
import { CarData } from '../../../types/part/partTypes'

const createAllCarParts = (car: CarData) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        car
    }
    try {

        const { data: { status } } = await apiInstance.post('/createAllCarParts', params , config)
        if(status === 'ok') {
            // dispatch(({
            //     type: storeDispatchTypes.deletePartImage,
            //     value: {
            //         id: partId,
            //         imageId
            //     },
            // }))
        }

    } catch (e) {
        console.error('create all car parts error', e)
    }
}

export default createAllCarParts
