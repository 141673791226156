import { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Form } from 'react-bootstrap'
import styles from './styles.module.scss'
import { initialCarState } from './initialCarState'
import OnboardingLayout from '../OnboardingLayout/OnboardingLayout'
import SalesText from '../../../components/_atoms/SalesText/SalesText'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import SalesButton from '../../../components/_atoms/SalesButton/SalesButton'
import { AppDispatch } from '../../../modules/store/customDispatch'
import { carReducer } from '../../NewCar/carReducer'
import PartCreateUpdateTable from '../../../components/_molecules/PartCreateUpdateTable/PartCreateUpdateTable'
import { useSelector } from '../../../modules/store/customSelector'
import { carCreateUpdateActionTypes, carFields, carInput, carSpecialPropsFields } from '../../../types/car/carTypes'
import { CatalogModification } from '../../../types/common/commonTypes'
import createAllCarParts from '../../../modules/redux/partList/CreateAllCarParts'
import { partCreateUpdateActionTypes, partInput } from '../../../types/part/partTypes'
import OverlayWithText from '../../../components/_atoms/OverlayWithText/OverlayWithText'
import Loader from '../../../components/_atoms/Loader/Loader'

const CarDisassembly = () => {
    const dispatchRedux = useDispatch<AppDispatch>()
    const [state, dispatch] = useReducer(carReducer, initialCarState)
    const [bodyTypes, setBodyTypes] = useState({} as Record<string, string>)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const filteredModifications = useSelector(state => state.carList.filteredModifications)
    const [isLoading, setIsLoading] = useState(false)

    const getField = (fieldToSearch: carFields) => state.fields.find((field: carInput) => field.field === fieldToSearch)

    const getBodyTypeId = (modificationId: number) => Number(filteredModifications.find((modificationObject: CatalogModification) => Number(modificationObject.id) === modificationId)?.bodyTypeId)
    const getBodyTypeRenderValue = (modificationId: number) => bodyTypes[String(getBodyTypeId(modificationId))] || ''

    const setIsDisabledFields = () => state.fields.map((field: carInput) => {

        if (field.field === carFields.bodyTypeId) {
            const modificationId = getField(carFields.modificationId).value

            return {
                ...field,
                value: getBodyTypeId(modificationId),
                specialProps: {
                    ...field.specialProps,
                    [carSpecialPropsFields.renderValue]: getBodyTypeRenderValue(modificationId),
                }
            }
        }

        const selectSearchText = field.specialProps?.renderValue
        const errorMessage = field.value ? '' : field.specialProps?.errorMessage

        if (field.specialProps?.fieldParent) {

            const fieldParentValue = getField(field.specialProps.fieldParent).value
            const fieldParentValueInChild = field.specialProps?.[carSpecialPropsFields.fieldParentValue]
            const isEnabled = fieldParentValue
            const isParentChanged = fieldParentValueInChild && fieldParentValue !== fieldParentValueInChild

            return {
                ...field,
                value: isParentChanged ? '' : field.value,
                specialProps: {
                    ...field.specialProps,
                    [carSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                    [carSpecialPropsFields.isDisabled]: !isEnabled,
                    [carSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                    [carSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                }
            }
        } else {
            return field
        }
    })

    const getVisibleColumn = (column: number) => state.fields.filter((field: carInput) => field.specialProps?.column === column)

    const getIsSubmitDisabled = () => {
        let isDisabled = false
        state.fields.forEach((fied: carInput) => {
            if (fied?.specialProps?.errorMessage) {
                isDisabled = true
            }
        })
        setIsSubmitDisabled(isDisabled)
    }

    const getApiObjectFromState = async () => {
        let apiObject = {
            markId: 0,
            modelId: 0,
            modificationId: 0,
            bodyTypeId: 0,
            year: 0,
        }
        let isObjectReady = true

        state.fields.map((item: carInput) => {
            if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                apiObject = { ...apiObject, [item.field]: item.value }
            }
            else if (item?.specialProps?.isRequired && (!item.value)) {
                dispatch({
                    type: carCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [carSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению'
                        }
                    }
                })
                isObjectReady = false
            } 
            else if (item?.specialProps?.errorMessage) {
                isObjectReady = false
            }
        })

        if (isObjectReady) {
            return apiObject
        } else {
            setIsSubmitDisabled(true)
        }
    }

    const disassemblyCar = async () => {
        setIsLoading(true)
        const newCar = await getApiObjectFromState()

        if (newCar) {
            await dispatchRedux(createAllCarParts(newCar))
        }
        setIsLoading(false)
    }

    const loadBodyTypes = async () => {
        try {
            const res = await axios.get('https://api.stock-pro.net/catalog/data/body_types.json')
            if (res.data) {
                setBodyTypes(res.data)
            }
        } catch (e) {
            console.error('loadBodyTypes error', e)
        }
    }

    useEffect(() => {
        loadBodyTypes().catch()
        dispatch({
            type: carCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } }
        })
    }, [])

    useEffect(() => {
        getIsSubmitDisabled()
        dispatch({
            type: partCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } }
        })
    }, [
        ...state.fields.filter((field: partInput) => field.specialProps?.isRequired).map((field: partInput) => field.value)
    ])

    return (
        <OnboardingLayout>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10}/>
                </OverlayWithText>
            )}
            <SalesText
                text={'Разобрать автомобиль!'}
                fontWeight={fontWeights.bold}
                fontSize={fontSizes.xxl}
            />
            <div className={'mt-4'}>
                <PartCreateUpdateTable
                    dispatch={dispatch}
                    fields={getVisibleColumn(1)}
                />
            </div>
            <div className='mt-4'>
                <Form.Check className={styles.checkbox}>
                    <Form.Check.Input
                        className={styles.checkboxLg}
                        checked
                    />
                    <Form.Check.Label>
                        <SalesText
                            text={'Разобрать на запчасти'}
                            fontSize={fontSizes.l}
                            fontWeight={fontWeights.bold}
                        />
                    </Form.Check.Label>
                </Form.Check>
            </div>
            {/* <div className='mt-4'>
                <Form.Check className={styles.checkbox}>
                    <Form.Check.Input
                        className={styles.checkboxLg}
                        defaultChecked
                    />
                    <Form.Check.Label>
                        <SalesText
                            text={'Проценить запчасти'}
                            fontSize={fontSizes.l}
                            fontWeight={fontWeights.bold}
                        />
                    </Form.Check.Label>
                </Form.Check>
            </div> */}
            <div className='mt-4'>
                    <SalesButton
                        backgroundColor={isSubmitDisabled ? colors.grayBorderApp : colors.blue}
                        paddingVerticalPixels={10}
                        paddingHorizontalPixels={36}
                        className='justify-content-center'
                        onClick={disassemblyCar}
                    >
                        <SalesText
                            text={'Готово!'}
                            color={colors.white}
                            fontSize={fontSizes.l}
                        />
                    </SalesButton>
            </div>
        </OnboardingLayout>
    )
}

export default CarDisassembly
