import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import styles from './styles.module.scss'
import OnboardingLayout from '../OnboardingLayout/OnboardingLayout'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import SalesText from '../../../components/_atoms/SalesText/SalesText'
import CustomLink from '../../../components/_atoms/CustomLink/CustomLink'
import SalesButton from '../../../components/_atoms/SalesButton/SalesButton'
import { useSelector } from '../../../modules/store/customSelector'
import { useDispatch } from '../../../modules/store/customDispatch'
import updateUserTaskResult from '../../../modules/redux/tasksService/UpdateUserTaskResult'
import createUserTaskResult from '../../../modules/redux/tasksService/CreateUserTaskResult'

const DownloadFromFile = () => {
    const [importUrl, setImportUrl] = useState('')
    const dispatch = useDispatch()
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls']
        }
    })

    const onboardingTask = useSelector(state => state.userData.tasksInfo?.onboarding)

    const handleOnboardingDone = () => {
        if (onboardingTask) {
            dispatch(updateUserTaskResult({
                code: 'onboarding', 
                userTaskId: onboardingTask.id,
                status: 'done',
                result: {
                    step: 'autoimport',
                    import_url: importUrl,
                }
            }))
        } else {
            dispatch(createUserTaskResult({
                code: 'onboarding',
                status: 'done',
                result: {
                    step: 'autoimport',
                    import_url: importUrl,
                }
            }))
        }
    }

    const handleBtnClick = () => {
        handleOnboardingDone()
    }

    return (
        <OnboardingLayout>
            <SalesText
                text={'Загрузить запчасти из файла'}
                fontWeight={fontWeights.bold}
                fontSize={fontSizes.xxl}
            />
            {acceptedFiles.length
            ? <div style={{ marginRight: '100px' }}>
                <SalesText
                    text={`Мы приняли ваш файл в работу и загрузим запчасти в течении двух дней.`}
                    fontWeight={fontWeights.normal}
                    fontSize={fontSizes.m}
                    marginTopPixels={28}
                />
                <SalesText
                    text={`Чтобы работа началась, необходимо оплатить услугу загрузки файла.`}
                    fontWeight={fontWeights.normal}
                    fontSize={fontSizes.m}
                    marginTopPixels={28}
                />
                <SalesButton
                    backgroundColor={colors.blue}
                    paddingVerticalPixels={10}
                    paddingHorizontalPixels={36}
                    marginTopPixels={30}
                    className='justify-content-center'
                    onClick={handleBtnClick}
                >
                    <SalesText
                        text={'Оплатить'}
                        color={colors.white}
                        fontSize={fontSizes.l}
                    />
                </SalesButton>
            </div>
            : <div style={{ marginRight: '80px' }}>
                <SalesText
                    text={
                        `Добавьте ссылку на ваш файл автозагрузки Авито или пришлите нам ваш
                        склад с Авито в формате эксель. И мы автоматически загрузим его в ваш аккаунт.`
                    }
                    fontWeight={fontWeights.normal}
                    fontSize={fontSizes.m}
                    marginTopPixels={28}
                />
                <Form.Group controlId='formFile' className={styles.customFormGroup}>
                    <Form.Control placeholder='Укажите ссылку на файл автозагрузки' value={importUrl} onChange={e => setImportUrl(e.target.value)}/>
                    <CustomLink text={'Где взять файл автозагрузки'} href={'https://docs.google.com/document/d/1S3mf3fZTCUwec7gO2AvxYiwTFMyxAtTN_4neQKr7SeE/edit?usp=sharing'} target={'_blank'} marginTop={4} marginLeft={14}/>
                </Form.Group>
                
                {/* <div {...getRootProps({ className: styles.dropAreaWrap })}>
                    <input {...getInputProps()} />
                    <SalesText text={'Либо перетащите сюда файл эксель'} fontSize={fontSizes.s} />
                </div>
                <CustomLink text={'Как скачать товары с Авито'} href={'https://docs.google.com/document/d/1JyB_c0FcULmMcHefG8SJ0D_kCvskpOe_6GERYRdKxLw/edit'} target={'_blank'} marginTop={4} marginLeft={14}/> */}

                <SalesText text={'Стоимость загрузки файла запчастей составляет 4 тыс руб'} marginTopPixels={40}/>
                <SalesButton
                    backgroundColor={colors.blue}
                    paddingVerticalPixels={10}
                    paddingHorizontalPixels={36}
                    marginTopPixels={24}
                    className='justify-content-center'
                    onClick={handleBtnClick}
                >
                    <SalesText
                        text={'Готово!'}
                        color={colors.white}
                        fontSize={fontSizes.l}
                    />
                </SalesButton>
            </div>
            }
        </OnboardingLayout>
    )
}

export default DownloadFromFile
